import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            window.location.href = "#/login";
        }
        throw error;
    }
);
